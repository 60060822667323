@import '../../../scss/theme-bootstrap';

#colorbox.welcome15-overlay-with-image {
  #cboxContent {
    @media #{$medium-up} {
      @include swap_direction(padding, 0 0 0 0);
      width: 845px !important;
    }
  }
  #cboxLoadedContent {
    @media #{$medium-up} {
      width: 845px !important;
    }
  }
  &.alt_layout1 {
    .welcome15-offer-signup-with-image {
      color: $color-white;
      background-color: transparent;
      padding-top: 52px;
      .email_input {
        margin-#{$ldirection}: 0;
        padding-#{$ldirection}: 1px;
        padding-#{$rdirection}: 17px;
      }
      .email_popover__content {
        overflow: visible;
      }
      .email_popover__shop_now_link {
        margin-bottom: -2px;
        margin-top: 17px;
        color: $color-periwinkle-blue;
        font-size: 12px;
        text-decoration: underline;
        font-weight: 700;
      }
      .email_popover__disclaimer {
        a {
          color: $color-white;
        }
      }
    }
    #cboxWrapper {
      @media #{$medium-up} {
        width: 845px !important;
      }
    }
    .welcome15-offer-signup-with-image--side {
      @media #{$small-only} {
        padding-top: 80px;
      }
      .welcome15-offer-signup-with-image__content {
        @media #{$small-only} {
          padding-top: 0;
          .email_input {
            input[type='submit'] {
              background-color: $color-navy;
            }
            input.form-text {
              color: $color-navy;
            }
          }
          input {
            background-color: $color-white;
            box-shadow: none;
          }
          .form-submit {
            border: 1px solid $color-periwinkle-blue;
            color: $color-periwinkle-blue;
          }
        }
      }
    }
  }
}

.welcome15-offer-signup-with-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 0 0 40px 0;
  @media #{$medium-up} {
    width: 845px;
    height: 395px;
    padding: 0;
  }
  .welcome15-offer-signup-with-image__content {
    background-color: transparent;
    padding: 0;
    position: relative;
    top: auto;
    #{$ldirection}: auto;
    height: auto;
    z-index: 2;
    @media #{$small-only} {
      height: 450px !important;
      margin-#{$ldirection}: auto;
      margin-#{$rdirection}: auto;
      width: 293px;
      z-index: 2010;
      padding-bottom: 0;
      letter-spacing: 1.9px;
      .email_popover_title {
        padding-top: 30px;
        margin-top: 0;
        overflow: hidden;
      }
      .email_popover__content {
        width: 320px;
        line-height: 35px;
        padding-top: 10% !important;
        h4 {
          font-size: 32px;
          line-height: 32px;
        }
        p {
          font-size: 12px;
          letter-spacing: 1.9px;
        }
      }
      .email_popover__social_icons {
        bottom: 0;
        #{$ldirection}: -7px;
        width: 320px;
      }
      .email_input {
        width: 318px;
        margin-top: 0;
        input {
          width: 290px;
        }
        input.form-text {
          text-align: $ldirection;
          margin-#{$rdirection}: 10px;
        }
      }
      .email_input input[type='submit'] {
        padding-#{$ldirection}: 17px;
        padding-#{$rdirection}: 17px;
      }
      .email_input input.form-submit {
        text-align: center;
      }
      .email_popover__disclaimer {
        line-height: 16px;
        #{$ldirection}: 2px;
      }
    }
    @media #{$medium-up} {
      width: 430px;
      padding: 35px 35px 30px;
    }
    .email_popover_title {
      font-size: 24px;
      text-align: $ldirection;
      @media #{$medium-up} {
        margin-top: 0px;
      }
    }
    .email_popover__content {
      overflow: hidden;
      font-size: 18px;
      padding-top: 30px;
    }
    .email_input input[type='submit'] {
      line-height: normal;
      height: 33px;
      padding: 0 12px;
    }
    .email_input input.form-text {
      display: inline;
      float: $ldirection;
      margin-#{$rdirection}: 20px;
      margin-bottom: 0;
      text-align: $ldirection;
      @media #{$medium-up} {
        width: 230px;
      }
    }
    .email_popover__disclaimer {
      color: $color-medium-gray;
    }
    .email_popover__social_icons {
      position: absolute;
      bottom: 0;
    }
    .email_popover__social_icons img {
      float: $ldirection;
      margin-#{$rdirection}: 28px;
      width: 32px;
      height: 32px;
    }
    .email_input {
      margin-top: 0;
      margin-bottom: 0;
      margin-#{$rdirection}: 20px;
      width: 100%;
      display: inline-block;
    }
  }
  &.welcome15-offer-signup-with-image--side {
    padding: 40px 0 0 0;
    @media #{$medium-up} {
      display: flex;
      flex-direction: row;
      padding: 0;
    }
    .welcome15-offer-signup-with-image__image {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      flex: none;
      height: 150px;
      @media #{$medium-up} {
        width: 410px;
        height: 395px;
      }
    }
    .welcome15-offer-signup-with-image__content {
      @media #{$medium-up} {
        margin: 35px 35px 30px;
        padding: 0 0 40px 0;
      }
      @media #{$small-only} {
        .email_popover__social_icons {
          top: 300px !important;
        }
      }
    }
  }
}
